<template>
    <b-modal
        id="EditBlockedDaysModal"
        size="xl"
        title="Sperrtage bearbeiten"
        @ok.prevent="handleOk"
        @shown.prevent="form.fill($parent.selectedBlockedDay)"
    >
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="type">Von: </label>
                    <input type="date" class="form-control form-control-sm" v-model="form.start_date" :class="{'is-invalid': form.errors.has('start_date')}">
                    <has-error :form="form" field="start_date"></has-error>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="type">Bis: </label>
                    <input type="date" class="form-control form-control-sm" v-model="form.end_date" :class="{'is-invalid': form.errors.has('end_date')}">
                    <has-error :form="form" field="end_date"></has-error>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    data()
    {
        return {
            form: new window.Form({
                id: null,
                start_date: null,
                end_date: null,
            })
        }
    },
    methods: {
        handleOk(evt)
        {
            evt.preventDefault();
            this.form.put("/holidays-blocked/" + this.form.id)
                .then((response) => {

                    let index = this.$parent.$parent.timeTracking.blocked_days.findIndex(element => element.id == this.form.id);
                    this.$parent.$parent.timeTracking.blocked_days.splice(index, 1, response.data.data);
                    this.$parent.$parent.closeModal("EditBlockedDaysModal");
                    this.$swal({
                        icon: "success",
                        title: "Sperrtage wurden geändert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {

                });
        },
    },
    mounted() {

    }
}
</script>

<style>

</style>