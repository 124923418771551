<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card card-primary card-outline">
                <div class="card-header">
                    <div class="card-title">
                        Sperrtage
                    </div>
                    <div class="card-tools">
                        <button class="btn btn-primary" v-if="$auth.check('holidays.period.create')" @click="$parent.openModal('createBlockedDaysModal')">
                            <i class="fas fa-plus"></i>
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Von</th>
                                <th>Bis</th>
                                <th>Aktionen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="blocked in $parent.timeTracking.blocked_days" :key="blocked.id">
                                <td>{{blocked.start_date | formatDate}}</td>
                                <td>{{blocked.end_date | formatDate}}</td>
                                <td>
                                    <button class="btn btn-warning btn-xs mr-1" v-if="$auth.check('holidays.period.edit')" @click="editBlockedDay(blocked)">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                    <button class="btn btn-danger btn-xs mr-1" v-if="$auth.check('holidays.period.destroy')" @click="deleteBlockedDay(blocked.id)">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- <div class="col-md-6">
            <div class="card card-primary card-outline">
                <div class="card-header">
                    <div class="card-title">
                        Verlauf
                    </div>
                </div>
            </div>
            <div class="card-body">

            </div>
        </div> -->
        <CreateBlockedDaysModal></CreateBlockedDaysModal>
        <EditBlockedDaysModal></EditBlockedDaysModal>
    </div>
</template>

<script>
import EditBlockedDaysModal from './modals/EditBlockedDaysModal.vue'
import CreateBlockedDaysModal from './modals/CreateBlockedDaysModal.vue'
export default {
    components: {CreateBlockedDaysModal, EditBlockedDaysModal },
    data() {
        return {
            selectedBlockedDay: null,
            selectedHolidays: null,
            holidays: {},
        }
    },
    methods: {
        editBlockedDay(element)
        {
            this.selectedBlockedDay = element;
            this.$parent.openModal("EditBlockedDaysModal");
        },
        editHolidays(element)
        {
            this.selectedHolidays = element;
            this.$parent.openModal("EditHolidaysModal");
        },



        deleteBlockedDay(id)
        {
          this.$swal({
                    title: "Möchtest du die Sperrtage wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Löschen`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .delete("/holidays-blocked/" + id)
                            .then(() => {
                                let index = this.$parent.timeTracking.blocked_days.findIndex(element => element.id == id);
                                this.$parent.timeTracking.blocked_days.splice(index, 1);

                                this.$swal({
                                    icon: "success",
                                    title: "Sperrtage gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                });
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Da ist wohl etwas schief gelaufen.'
                                });
                            })
                    }
                });
        },
    },
    mounted() {
    }
}
</script>

<style>

</style>